<template>
    <div class="container">
        <div>
            <div class="empty-top"></div>
            <div class="intro">
              <pre>"내면의 어두운 자아와 
빛나는 자아가 대립하고 있다."</pre>
            </div>
            <div v-for="(row, index) in rows" :key="row.id">
              <line-container :align="index % 2 === 0 ? 'left' : 'right'">
                <div v-show="row.length" class="balloon" :class="index % 2 === 0 ? 'balloon-left' : 'balloon-right'">{{ row }}</div>
              </line-container>
            </div>
            <line-container v-show="newRowName.length == 0"  :align="rows.length % 2 !== 0 ? 'left' : 'right'">
              <div class="balloon" :class="rows.length % 2 !== 0 ? 'balloon-left' : 'balloon-right'">{{ dots }}</div>
            </line-container>
            <div class="button-container">
              <div class="button" @click="onSave">저장</div>
            </div>
            <div class="button-container">
              <div class="button del" @click="onDel">지우기</div>
            </div>
            <div class="empty"></div>
        </div>
        <div class="fixed-input">
            <input type="text" v-model="newRowName" @input="updateLastItem" @keypress.enter="addItem" placeholder="화자를 바꾸려면 엔터를 치세요">
            <!-- <button @click="addItem">추가</button> -->
        </div>
    </div>
</template>

<script>
import LineContainer from './components/LineContainer.vue';

export default {
  name: 'App',
  components: {
    LineContainer
  },
  data(){
    return {
      rows: ['',],
      newRowName: '',
      lastText: '',
      dots: '.'
    }
  },
  mounted(){
    
    {
      var queryString = window.location.search;
      var searchParams = new URLSearchParams(queryString);
      var paramValue = searchParams.get('data');

      try {
        const rows = JSON.parse(paramValue)
        if(rows) this.rows = rows;        
      } catch (error) {
        alert('오류')
      }
    }

    setInterval(() => {
      this.dots = this.dots + '.';
      if(this.dots == '....') this.dots = "."
    }, 300);

    this.scrollDown();
  },
  computed: {
    typing(){
      return "..."
    }
  },
  methods: {
    onDel(){
      var userConfirmed = confirm("정말로 삭제할까요?");

      if (userConfirmed) {
        window.location = '/'
      } else {
        console.log("사용자가 '취소'를 클릭했습니다.");
      }
    },
    onSave(){
      const str = JSON.stringify(this.rows)
      var param = encodeURIComponent(str);

      var protocol = window.location.protocol; // 프로토콜 (예: "http:" 또는 "https:")
      var hostname = window.location.hostname; // 호스트명 (예: "www.example.com")
      var port = window.location.port; // 포트 (문자열, 예: "8080" 또는 "")
      var currentHostWithoutParams = protocol + "//" + hostname + (port ? ":" + port : "");
      const newUrlPath = `${currentHostWithoutParams}/?data=${param}`

      {
          var textArea = document.createElement("textarea");
          textArea.value = newUrlPath;
          textArea.style.position = "fixed";
          textArea.style.top = "-9999px";
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          
          alert(`내용이 클립보드로 복사되었습니다. 저장해서 사용하세요`)

          history.pushState(null, null, newUrlPath);
      }
    },
    lastIndex(){
      return this.rows.length - 1;
    },
    scrollDown(){
      setTimeout(() => {
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth' // 부드러운 스크롤 효과
        });            
      }, 1)
    },
    addItem: function() {
      {
          // 입력값을 rows 배열에 추가
          this.newRowName = ''; // 입력 창 초기화
          this.rows.push({ id: Date.now(), name: this.newRowName });

          this.scrollDown()
      }
    },
    updateLastItem(event) {
      this.newRowName = event.target.value;
        const lastIndex = this.lastIndex();
        if (lastIndex >= 0) {
            // 입력 창의 내용을 마지막 항목에 업데이트
            this.lastText = this.newRowName;
            this.rows[lastIndex] = this.newRowName;
        }
    }
  }  
}
</script>

<style>
.container{
  font-size: 14px;
  max-width: 480px;
  margin: 0 auto;
}
    .cell {
        width: 70%;
        word-wrap: break-word; /* 내용의 줄바꿈을 허용합니다. */
    }
    .left-cell {
        float: left;
    }
    .right-cell {
        float: right;
    }

    .fixed-input {
      display: flex;
        position: fixed;
        bottom: 0;
        /* left: 0; */
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
        background-color: #fff; /* 원하는 배경색 지정 */
        /* padding: 10px;  */
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 그림자 추가 (선택 사항) */
    }
    .fixed-input input {
        /* width: 100%; */
        flex: 1;
        margin: 15px;
        border: none; /* 입력 창 테두리 없애기 (선택 사항) */
        outline: none;
        font-size: 16px;
    }    
    
    .bubble{
      background: dodgerblue;
      color: white;
      width: fit-content;
    }  
    /* div를 말풍선 모양으로 스타일링 */

    .balloon {
        color: white;
        padding: 10px 20px;
        position: relative;
        display: inline-block;
        margin-top: 10px;
    }

    .balloon-left {
      background-color: dodgerblue;
      border-radius: 20px 20px 20px 0;
      margin-right: 50px;
    }

    .balloon-right {
      background-color: deeppink;
      border-radius: 20px 20px 0px 20px;
      margin-left: 50px;
    }
    .empty{
      height: 50px;
      /* background: yellow; */
    }
    .empty-top{
      height: 100vh;
      /* background: yellow; */
    }
    .button-container{
      text-align: center;
    }
    .button{
      color: white;
      background: orange;
      border-radius: 20px;
      padding: 10px 20px;
      position: relative;
      display: inline-block;
      margin-top: 10px;
    }
    .del{
      background: white;
      color: red;
    }
    .intro{    
      font-size: 16px;
      text-align: center;
      padding: 20px;
      background: lightgray;
      border-radius: 20px 
    }

</style>