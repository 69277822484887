<template>
    <div :class="containerClass">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      align: {
        type: String,
        default: 'left', // left 또는 right를 기본값으로 설정합니다.
      },
    },
    computed: {
      containerClass() {
        // props 'align' 값에 따라 적절한 CSS 클래스를 반환합니다.
        return {
          'align-left': this.align === 'left',
          'align-right': this.align === 'right',
        };
      },
    },
  };
  </script>
  
  <style scoped>
  /* CSS 클래스에 따라 좌우 정렬 스타일을 지정합니다. */
  .align-left {
    display: flex; /* Flexbox 컨테이너로 설정 */
    justify-content: flex-start; /* 좌정렬 */
  }
  
  .align-right {
    display: flex; /* Flexbox 컨테이너로 설정 */
    justify-content: flex-end; /* 우정렬 */
  }
  </style>
  